import React, { useState } from 'react';
import WelcomeView from './ChatWelcomeView';
import { TextField, Button } from '@material-ui/core';
import ReactMarkdown from 'react-markdown'; 
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { connect } from 'react-redux';
import ImageIcon from '../../../assets/image_icon.svg';
import ThumbDownIcon from '../../../assets/thumb_down_icon.svg';
import { dislikeMessageBatch } from '../api/chatApi';
import '../styles/ChatMessages.css';

const ChatMessages = ({ messages, onSendMessage, auth }) => {
    const [inputValue, setInputValue] = useState('');
    const [activeModel, setActiveModel] = useState('GPT4o');

    const handleSend = () => {
        if (inputValue.trim() !== '') {
            onSendMessage(inputValue);
            setInputValue('');
        }
    };

    const handleDislike = (message) => {
        try {
            console.log("Dislike:", message);
            dislikeMessageBatch(message.messageBatchId);
        }
        catch (error) {
            console.error("Error al enviar dislike:", error);
        }

    };

    const CodeBlock = ({ language, value }) => (
        <div className="code-block">
            <SyntaxHighlighter language={language} style={materialDark}>
                {value}
            </SyntaxHighlighter>
            <Button
                className="copy-button"
                onClick={() => navigator.clipboard.writeText(value)}
            >
                Copiar
            </Button>
        </div>
    );

    const markdownComponents = {
        code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            const codeValue = String(children).replace(/\n$/, '');
            return !inline && match ? (
                <CodeBlock language={match[1]} value={codeValue} />
            ) : (
                <code className={className} {...props}>
                    {children}
                </code>
            );
        },
    };

    const renderBotMessage = (msg) => {

        const senderRole = msg.sender === 'user' ? auth.name : 'Asistente';
    
        if (msg.sender === 'bot' && msg.responses) {
            const response = msg.responses[activeModel] || "Cargando...";
    
            return (
                <div className={`message-wrapper ${msg.sender}-wrapper`}>
                    <span className="message-sender">
                        {senderRole}
                    </span>
                    <div className={`message-container ${msg.sender}-message`}>
                        <div className="bot-tips">
                            <div className="tip-buttons">
                                <Button
                                    variant={activeModel === 'GPT4o' ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={() => setActiveModel('GPT4o')}
                                >
                                    Tip 1
                                </Button>
                                <Button
                                    variant={activeModel === 'Claude' ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={() => setActiveModel('Claude')}
                                >
                                    Tip 2
                                </Button>
                                <Button
                                    variant={activeModel === 'GPT4oStackOverflow' ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={() => setActiveModel('GPT4oStackOverflow')}

                                >
                                    Tip 3
                                </Button>
                            </div>
                            <div className="message-content">
                                <ReactMarkdown components={markdownComponents}>
                                    {response}
                                </ReactMarkdown>
                            </div>
                            <div className="chat-dislike-container">
                                <Button
                                    color="secondary"
                                    size="small"
                                    onClick={() => handleDislike(msg)}
                                    className="chat-dislike-button"
                                >
                                    <img src={ThumbDownIcon} alt="dislike" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    
        return (
            <div className={`message-wrapper ${msg.sender}-wrapper`}>
                <span className="message-sender">{senderRole}</span>
                <div className={`message-container ${msg.sender}-message`}>
                    <div className="message-content">
                        <ReactMarkdown components={markdownComponents}>
                            {msg.text}
                        </ReactMarkdown>
                    </div>
                    {msg.sender === 'bot' && (
                    <div className="chat-dislike-container">
                        <Button
                            color="secondary"
                            size="small"
                            onClick={() => handleDislike(msg)}
                            className="chat-dislike-button"
                        >
                            <img src={ThumbDownIcon} alt="dislike" />
                        </Button>
                    </div>
                    )}
                </div>
            </div>
        );
    };    

    return (
        <div className="chat-messages">
            {messages.length === 0 && (
                <div className="welcome-container">
                    <WelcomeView setCurrentMessage={setInputValue} />
                </div>
            )}
            <div className="message-list">
                {messages.map((msg, index) => renderBotMessage(msg))}
            </div>

            <div className="chat-message-input">
                <TextField
                    label="Escribe tu mensaje."
                    variant="outlined"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleSend()}
                    className="message-input-field"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSend}
                    className="send-button"
                >
                    Enviar
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});
    
export default connect(mapStateToProps)(ChatMessages);