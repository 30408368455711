import { fk, attr, Model } from 'redux-orm';

import {
    FETCH_GET_COMMITS,
} from '../../constants/api/commits';

class Commit extends Model {
    static create(props) {
        if (props.hasOwnProperty(this.idAttribute) && this.idExists(props[this.idAttribute])) {
            const model = this.withId(props.id);
            return model.update(props);
        }
        return super.create(props);
    }

    static reducer(action, modelClass) {
        const { type, payload } = action;
        switch (type) {
            case `${FETCH_GET_COMMITS}_SUCCESS`: {
                const array = payload.body;
                for (let i = 0; i < array.length; i += 1) {
                    const obj = array[i];
                    modelClass.create(obj);
                }
                break;
            }
            default:
                break;
        }
        return null;
    }
}

Commit.modelName = 'Commit';

Commit.fields = {
    id: attr(),
    url: attr(),
    message: attr(),
    created_at: attr(),
    task_id: fk('Task', 'commits'),
};

export default Commit;