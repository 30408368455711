import React, { useState } from "react";
import { connect } from "react-redux";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { MoreVert as MoreVertIcon } from "@material-ui/icons";
import "../styles/ChatSideBar.css";
import { deleteChat } from "../api/chatApi";

import AddIcon from "../../../assets/add_icon.svg";
import ArchiveIcon from "../../../assets/archive_icon.svg";
import DeleteIcon from "../../../assets/delete_icon.svg";

function SideBar({ setSelectedChatId, conversations, setConversations }) {
  const [showArchivedChats, setShowArchivedChats] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [localSelectedChatId, setLocalSelectedChatId] = useState(null);

  const toggleArchivedChats = () => setShowArchivedChats((prev) => !prev);

  console.log("Conversaciones en flujo principal:", conversations);

  const handleChatSelection = (chatId) => {
    setSelectedChatId(chatId);
    setLocalSelectedChatId(chatId);
    console.log("Chat seleccionado:", chatId);
  };

  const handleNewChat = () => {
    setSelectedChatId(null);
    setLocalSelectedChatId(null); 
    console.log("Nuevo chat creado");
  };

  const handleMenuAction = async (action) => {
    switch (action) {
      case "archive":
        console.log(`Chat ${localSelectedChatId} archivado`);
        break;
      case "delete":
        try {
          await deleteChat(localSelectedChatId);
          console.log(`Chat ${localSelectedChatId} eliminado`);
          setConversations((prev) =>
            prev.filter((chat) => chat.id !== localSelectedChatId)
          );
        } catch (error) {
          console.error(`Error al eliminar el chat ${localSelectedChatId}:`, error);
        }
        break;
      default:
        break;
    }
    handleMenuClose();
  };

  const handleMenuOpen = (event, chatId) => {
    setMenuAnchorEl(event.currentTarget);
    setLocalSelectedChatId(chatId);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setLocalSelectedChatId(null);
  };

  return (
    <div className="chatmodal-sidebar">
      <div className="sidebar-create-new-chat">
        <button className="sidebar-button" onClick={handleNewChat}>
          Nuevo chat
          <img src={AddIcon} alt="add-icon" className="icon-right" />
        </button>
      </div>
      <Divider/>
      <div className="sidebar-chats-container">
        <List className="sidebar-chats">
          {conversations.map((chat) => (
            <div key={chat.id}>
              <ListItem 
                button 
                onClick={() => handleChatSelection(chat.id)}
                style={{
                  backgroundColor: localSelectedChatId === chat.id ? '#d3d3d3' : 'transparent',
                  transition: 'background-color 0.3s ease',
                }}
              >
                <div className="chat-item-container">
                  <div className="chat-item-text">
                    <ListItemText
                      primary={chat.title || "Sin Título"}
                      secondary={
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          display="block"
                        >
                          {new Date(chat.updatedAt || new Date()).toLocaleDateString("es-ES", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          })}
                        </Typography>
                      }
                    />
                  </div>
                  <IconButton
                    edge="end"
                    onClick={(e) => handleMenuOpen(e, chat.id)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </div>
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
      </div>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleMenuAction("delete")}>
          <img src={DeleteIcon} alt="delete-icon" className="icon-right" />
          Eliminar
        </MenuItem>
      </Menu>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(SideBar);
